const VIEW_ITEM_LIST_EVENT = 'view_item_list'
const SELECT_ITEM_LIST_EVENT = 'select_item'
const VIEW_ITEM_EVENT = 'view_item'
const ADD_TO_CART_EVENT = 'add_to_cart'
const VIEW_CART_EVENT = 'view_cart'
const BEGIN_CHECKOUT_EVENT = 'begin_checkout'
const PURCHASE = 'purchase'

/**
 * Tracks Google Ecommerce event for a product.
 * @param {*} params
 */
const trackEcommerceEvent = (eventName, params) => {
  try {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: eventName, 
      ecommerce: {...params } 
    });
  } catch (error) {
    // Avoid crashing the app if there is an error
    console.error('trackEcommerceEvent', error);
  }
}

function getCurrency() {
  return process.env.VUE_APP_REGION === "US" ? 'USD' : 'AUD';
}

export {
  trackEcommerceEvent,
  getCurrency,

  // EVENT NAMES
  VIEW_ITEM_LIST_EVENT,
  SELECT_ITEM_LIST_EVENT,
  VIEW_ITEM_EVENT,
  ADD_TO_CART_EVENT,
  VIEW_CART_EVENT,
  BEGIN_CHECKOUT_EVENT,
  PURCHASE,
}